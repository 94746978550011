/** @jsx jsx */
import { jsx, Box, Divider, Label, Button, Input, Styled, Flex, Checkbox } from 'theme-ui'
import React, { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from '../../context'
import {navigate} from  "gatsby"

// import GoogleLogo from "../../images/google.svg"

export const SignUp = ({redirect="/"}) => {
  
  const {firebase} = useContext(FirebaseContext)

  const [errorMessage, setErrorMessage] = useState("")

  const [values, setValues] = useState({
    breeder: false,
    breederId: "",
    email: "",
    username: "",
    password: "",
    passwordConfirmation: "",
  })

  console.log(values)

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const breederId = urlParams.get('breederId')
    console.log(breederId);
    setValues({...values, breederId: breederId})
  },[])

  const handleChange = (event) => {
    const {name, value} = event.target
    setErrorMessage("")
    setValues({...values, [name]: value})
  }

  const handleCheck = (event) => {
    const {name, checked} = event.target
    setErrorMessage("")
    setValues({...values, [name]: checked})
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (values.password === values.passwordConfirmation) {
      firebase.register({
        username: values.username,
        email: values.email, 
        password: values.password,
        breeder: values.breeder,
        breederId: values.breederId
        }).then(navigate(redirect)).catch(error => {
        console.log(error);
        setErrorMessage(error.message)
      })
    } else {
      setErrorMessage("Passwords don't match")
    }
  }

  // const googleSignUp = () => {
  //   firebase.signInWithGoogle().catch(error => {
  //     console.log(error);
  //     setErrorMessage(error.message)
  //   })
  // }

  return (
    <>
    <Styled.h1>Sign Up</Styled.h1>

    {/* <Flex sx={{flexDirection: 'column', mb: 5}}>
      <Button onClick={googleSignUp} sx={{backgroundColor: 'white', color: '#757575', boxShadow: 'medium', borderRadius: 'medium',  width: '100%'}}>
        <Flex sx={{alignItems: 'center', justifyContent: 'center'}}>
          <img src={GoogleLogo} sx={{mb: '0px', mr: 3, width: '30px'}} /> Sign in with Google
        </Flex>
      </Button>
    </Flex>


    <Flex sx={{pb: 5, width: '100%', alignItems: 'center'}}>
      <span sx={{mr: 3, fontWeight: 'bold'}}>or</span><Divider sx={{width: '100%'}}/>
    </Flex> */}

    {!!errorMessage && (
      <Flex sx={{pb: 5, justifyContent: 'center'}}>
        <span sx={{ width: '100%', textAlign: 'center', backgroundColor: 'red', color: 'white', borderRadius: 'medium', p: 4, fontSize: '14px', fontWeight: 'bold'}}>{errorMessage}</span>
      </Flex>
    )}
    <form onSubmit={onSubmit}>
      <Label htmlFor='username'>Username</Label>
        <Input
          name='username'
          id='username'
          type='text'
          onChange={handleChange}
          required
          mb={3}
        />
      <Label htmlFor='email'>Email</Label>
        <Input
          name='email'
          id='email'
          type='email'
          onChange={handleChange}
          required
          mb={3}
        />
      <Label htmlFor='password'>Password</Label>
      <Input
        type='password'
        name='password'
        id='password'
        onChange={handleChange}
        required
        minLength={6}
        mb={3}
      />
      <Label htmlFor='passwordConfirmation'>Password Confirmation</Label>
      <Input
        type='password'
        name='passwordConfirmation'
        id='passwordConfirmation'
        onChange={handleChange}
        required
        minLength={6}
        mb={3}
      />

      {!values.breederId && (
        <Label htmlFor='breeder' sx={{my: 4}}>I'm a dog breeder?
          <Checkbox
            name='breeder'
            id='breeder'
            defaultChecked={false}
            onChange={handleCheck}
            mb={3}
          />
        </Label>
      )}
      <Button>Submit</Button>
    </form>
    


    </>
  )
}