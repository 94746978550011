// import ...
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

export const PrivateRoute = ({ component: Component, location, user, loading, ...rest }) => {
  
    const [load, setLoad] = useState(false)

    useEffect(() => {

    },[loading])

    if (loading) {
      return <Component user={user} {...rest} />
    } else {
      return "loading..."
    }
  
}
