/** @jsx jsx */
import { jsx, Box, Container, Flex} from 'theme-ui'
import React from "react"

import SEO from "../components/seo"
import {SignUp} from "../components/Auth"

const SignUpPage = () => (
  <section>
    <SEO title="Sign Up" />
    <Flex sx={{minHeight: 'calc(100vh - 90px)', justifyContent: 'center', alignItems: 'center'}}>
      <Container sx={{width: '100%', maxWidth: 'small'}}>
        <Box sx={{mx: '5'}}>
          <SignUp redirect={'/profile'}/>
        </Box>
      </Container>
    </Flex>
  </section>
)

export default SignUpPage