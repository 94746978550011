/** @jsx jsx */
import { jsx, Box, Divider, Label, Button, Input, Styled, Flex } from 'theme-ui'
import React, { useState, useContext } from 'react'
import { FirebaseContext } from '../../context'
import {navigate} from "gatsby"
// import GoogleLogo from "../../images/google.svg"


export const SignIn = ({redirect="/"}) => {
  
  const {firebase} = useContext(FirebaseContext)

  const [errorMessage, setErrorMessage] = useState("")

  const [values, setValues] = useState({
    email: "",
    password: ""
  })

  const handleChange = (event) => {
    const {name, value} = event.target
    setErrorMessage("")
    setValues({...values, [name]: value})
  }

  const onSubmit = (e) => {
    e.preventDefault();
    firebase.login({email: values.email, password: values.password}).catch(error => {
      console.log(error);
      setErrorMessage(error.message)
    }).then(navigate(redirect))
  }


  // const googleSignUp = () => {
  //   firebase.signInWithGoogle().catch(error => {
  //     console.log(error);
  //     setErrorMessage(error.message)
  //   })
  // }


  return (
    <>
    <Styled.h1>Sign In</Styled.h1>


    {/* <Flex sx={{flexDirection: 'column', mb: 5}}>
      <Button onClick={googleSignUp} sx={{backgroundColor: 'white', color: '#757575', boxShadow: 'medium', borderRadius: 'medium',  width: '100%'}}>
        <Flex sx={{alignItems: 'center', justifyContent: 'center'}}>
          <img src={GoogleLogo} sx={{mb: '0px', mr: 3, width: '30px'}} /> Sign in with Google
        </Flex>
      </Button>
    </Flex>


    <Flex sx={{pb: 5, width: '100%', alignItems: 'center'}}>
      <span sx={{mr: 3, fontWeight: 'bold'}}>or</span><Divider sx={{width: '100%'}}/>
    </Flex> */}


    {!!errorMessage && (
      <Flex sx={{pb: 5, justifyContent: 'center'}}>
        <span sx={{ width: '100%', textAlign: 'center', backgroundColor: 'red', color: 'white', borderRadius: 'medium', p: 4, fontSize: '14px', fontWeight: 'bold'}}>{errorMessage}</span>
      </Flex>
    )}
    <form onSubmit={onSubmit}>
      <Label htmlFor='email'>Email</Label>
        <Input
          required
          name='email'
          id='email'
          type='email'
          onChange={handleChange}
          mb={3}
        />
      <Label htmlFor='password'>Password</Label>
      <Input
        required
        type='password'
        name='password'
        id='password'
        onChange={handleChange}
        mb={3}
      />
      <Button>Submit</Button>
    </form>
    </>
  )
}